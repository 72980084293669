<template>
  <div class="container">
    <Header active="expert" />
    <div class="main">
      <el-carousel class="banner" @change="bannerChange" interval="6000">
        <el-carousel-item>
          <div class="item">
            <img
              class="img"
              :src="require('../../../assets/images/website/expert.jpg')"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="detail">
        <el-row>
          <el-col :span="6"
            ><img class="photo" :src="expert.imagePath"
          /></el-col>
          <el-col :span="18" class="simple">
            <el-row class="item">
              <el-col :span="4" class="left">姓名：</el-col>
              <el-col :span="8" class="right">{{ expert.name }}</el-col>
              <el-col :span="4" class="left">性别：</el-col>
              <el-col :span="8" class="right">{{ expert.name }}</el-col>
            </el-row>
            <el-row class="item">
              <el-col :span="4" class="left">国籍：</el-col>
              <el-col :span="8" class="right">{{ expert.countryText }}</el-col>
              <el-col :span="4" class="left">民族：</el-col>
              <el-col :span="8" class="right">{{ expert.nationText }}</el-col>
            </el-row>
            <el-row class="item">
              <el-col :span="4" class="left">政治面貌：</el-col>
              <el-col :span="8" class="right">{{ expert.nationText }}</el-col>
              <el-col :span="4" class="left">职称：</el-col>
              <el-col :span="8" class="right">{{ expert.academic }}</el-col>
            </el-row>
            <el-row class="item">
              <el-col :span="4" class="left">毕业院校：</el-col>
              <el-col :span="8" class="right">{{ expert.school }}</el-col>
              <el-col :span="4" class="left">学历：</el-col>
              <el-col :span="8" class="right">{{ expert.qualifyText }}</el-col>
            </el-row>
            <el-row class="item">
              <el-col :span="4" class="left">所在城市：</el-col>
              <el-col :span="8" class="right">{{ expert.regionName }}</el-col>
              <el-col :span="4" class="left">专业分类：</el-col>
              <el-col :span="8" class="right">{{ expert.classifyText }}</el-col>
            </el-row>
            <el-row class="btn">
              <el-col :span="24">
                <el-button
                  type="warning"
                  icon="el-icon-s-promotion"
                  @click="serviceApply"
                  >业务合作申请</el-button
                >
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <div class="subtitle">简要介绍</div>
        <div
          v-if="expert.introduceBrief !== undefined"
          v-html="expert.introduceBrief"
          class="introB"
        ></div>
        <div v-else class="intro-no">暂无简要介绍</div>
        <div class="subtitle">详细介绍</div>
        <div
          v-if="expert.introduce !== undefined"
          v-html="expert.introduce"
          class="intro"
        ></div>
        <div v-else class="intro-no">暂无简要介绍</div>
      </div>
    </div>
    <el-dialog title="业务合作申请" v-model="applyState">
      <el-form :model="ruleForm" label-width="80px" class="form">
        <el-form-item label="姓名">
          <el-input
            v-model="ruleForm.name"
            placeholder="请填写您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请填写您的联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="业务描述">
          <el-input
            v-model="ruleForm.description"
            :rows="2"
            type="textarea"
            placeholder="请简要描述您的业务需求"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="applyState = false">取消</el-button>
          <el-button type="primary" @click="serviceApplySave"> 确认 </el-button>
        </span>
      </template>
    </el-dialog>
    <Footer />
  </div>
</template>
<script>
import View from './View.js'
export default {
  ...View
}
</script>
<style lang="scss" scoped>
@import './View.scss';
</style>
