import 'animate.css'
import '../../../assets/icon/font-icon.js'
import Header from '../common/Header.vue'
import Footer from '../common/Footer.vue'
import Pagination from '../../../components/Pagination.vue'
import { ElCarousel, ElCarouselItem, ElTabs, ElTabPane, ElDialog, ElSelect, ElOption, ElForm, ElFormItem, ElInput } from 'element-plus'
export default {
    components: {
        Header: Header,
        Footer: Footer,
        Pagination: Pagination,
        [ElCarousel.name]: ElCarousel,
        [ElCarouselItem.name]: ElCarouselItem,
        [ElTabs.name]: ElTabs,
        [ElTabPane.name]: ElTabPane,
        [ElDialog.name]: ElDialog,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElForm.name]: ElForm,
        [ElFormItem.name]: ElFormItem,
        [ElInput.name]: ElInput
    },
    data () {
        return {
            applyState: false,
            code: '',
            expert: {},
            ruleForm: {
                name: '',
                phone: '',
                description: ''
            }
        }
    },
    mounted () {
        var query = this.$route.query
        this.code = query.code
        this.retrieveExpert()
    },
    methods: {
        async retrieveExpert () {
            var pd = { code: this.code }
            const { data: res } = await this.$http.post(this.BMS_URL + '/expert/expertAPC/retrieveViewExpert', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.expert = res.data
            }
        },
        serviceApply () {
            this.applyState = true
        },
        serviceApplySave () {
            if (this.ruleForm.name === '') {
                this.$alert('请填写姓名', '提示信息')
            } else if (this.ruleForm.phone === '') {
                this.$alert('请填写手机号码', '提示信息')
            } else {
                this.$alert('提交成功请耐心等待', '提示信息').then(() => {
                    this.applyState = false
                })
            }
        }
    }
}
